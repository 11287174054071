<template>
    <div class="mainContainer bottom-navbar-segment">
        <div
            v-for="(child, index) in eachButtonArray"
            :key="index"
            class="eachButtonNav"
            @click="changeActiveBtn(index)"
        >
            <img
                :src="getImage(selectedButton, index)" 
                style="width: 33px; height: 32px;"
            >
            <span
                v-if="selectedButton == index && index!=0 && index!=1 && !appcubeCheck"
                style="color: #48ACEF !important"
            >
                {{ child.buttonTag }}
            </span>
            <span v-else-if="appcubeCheck && selectedButton == index" style="color: #48ACEF !important">
                {{ child.buttonTag }}
            </span>
            <span v-else>{{ child.buttonTag }}</span>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'BottomNavigationBar',
    props: {
        selectedOption: {
            type: Number,
            required: true,
        },
    },
    data: () => {
        return {
            eachButtonArray: [
                {
                    src: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_home.svg',
                    srcBlue: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_home.svg',
                    buttonTag: 'Home',
                },
                {
                    src: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_pharmacy.svg',
                    srcBlue: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_pharmacy.svg',
                    buttonTag: 'Pharmacy',
                },
                {
                    src: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_consultation.svg',
                    srcBlue: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_consultation.svg',
                    buttonTag: 'Consultation',
                },
// {
                //     src: 'https://s3iconimages.mymedicine.com.mm/inactiveNurseIcon.svg',
                //     srcBlue: 'https://s3iconimages.mymedicine.com.mm/activeNurseIcon.svg',
                //     buttonTag: 'Nurse',
                // },
                {
                    src: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_inactive_profile.svg',
                    srcBlue: 'https://s3iconimages.mymedicine.com.mm/bottom_nav_icon_active_profile.svg',
                    buttonTag: 'Profile',
                },
            ],
            selectedButton: -1,
            appcubeCheck: window.xm ? 1 : 0
        };
    },
    mounted() {
        this.selectedButton = this.$store.getters.getSelectedIndex;
        this.selectedButton = this.selectedOption;
    },
    methods: {
        changeActiveBtn(index) {
            this.currentCustomer = this.$cookies.get("customerToken");
            if (index != 0 && index != 1 && !this.appcubeCheck) {
                this.$store.dispatch('switchActiveButtonBottomNavigation', {data: {index}});
                this.selectedButton = this.$store.getters.getSelectedIndex;
            }
            switch(index) {
                case 0:
                    if (!this.appcubeCheck)
                        window.open('https://mymedicine.com.mm/', '_blank');
                    else
                        if (!window.xm) {
                            this.$router.push({
                                name: 'CustomerHomeScreen'
                            });
                        } else {
                            this.$router.push({
                                name: 'CommonHomePage'
                            });
                        }
                    break;
                case 1:
                    if (!this.appcubeCheck)
                        window.open('https://mymedicine.com.mm/shop', '_blank');
                    else
                        if (!window.xm) {
                            this.$router.push({
                                name: 'CustomerHomeScreen'
                            });
                        } else {
                            this.$router.push({
                                name: "ePharmacyHomePage"
                            });
                        }
                    break;
                case 2:
                    this.$router.push({
                        name: 'CustomerHomeScreen'
                    })
                    break;
                // case 3:
                //     this.$router.push({
                //         name: 'NurseBookingCommonPage'
                //     })
                //     break;
                case 3:
                    if (this.currentCustomer) {
                        this.$router.push({
                            name: "UserProfile"
                        });
                    } else {
                        this.$router.push({
                            name: 'Login'
                        });
                    }
                    break;
            }
        },
        getImage(selectedButton, index) {
            if(this.appcubeCheck) {
                if (selectedButton === index) {
                    return this.eachButtonArray[index].srcBlue;
                } else {
                    return this.eachButtonArray[index].src;
                }
            } else {
                if (index!=0 && index!=1 && selectedButton === index) {
                    return this.eachButtonArray[index].srcBlue;
                } else {
                    return this.eachButtonArray[index].src;
                }
            }
        },
    },
};

</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../src/scss/classes.scss';
/* src\components\nurseBookingPageComponents\bottomNavBarComponent.vue
src\scss\classes.scss */
.mainContainer {
    @media screen and (min-width: 601px) {
        width: 420px !important;
    }
    @media screen and (max-width: 600px) {
        width: 100% !important;
    }
    height: 75px;
    background:#FFF !important;
    box-shadow: 2px 0px 20px 0px rgba(24, 57, 107, 0.05) !important;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
}
.eachButtonNav {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.eachButtonNav span {
    margin-top: 4px;
    color: #828282 !important;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>
  